import React from "react"

import SEO from "../components/SEO"

import NA404 from "../components/NA404"

import Layout from "../layout"
import ContentRow from "../components/ContentRow"

const NotFoundPage = () => (
  <>
    <Layout>
      <SEO
        title="404"
        keywords={[
          `Into Numbers`,
          `Data Science`,
          `Web Development`,
          `Digitalisierung`,
          `Trainings`,
          `Business Intelligence`,
        ]}
      />
      <ContentRow style={{ display: "flex" }}>
        <NA404 />
      </ContentRow>
    </Layout>
  </>
)

export default NotFoundPage
