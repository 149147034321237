import React from "react"
import styled from "styled-components"

import Grafics from "./grafics"

const GraficsWrapper = styled.div`
  margin: ${props => props.theme.spaces.xxxl} auto;
  width: 250px;
  height: 200px;
`

function NA404() {
  return (
    <GraficsWrapper>
      <Grafics name={"na404"} alt={"Into Numbers 404 Graphic"} />
    </GraficsWrapper>
  )
}

export default NA404
